<template>
    <div class="main-content">
        <MobileProductTypeBubbles />
        <HeroSlider></HeroSlider>
        <Trustpilot></Trustpilot>
        <Brand></Brand>
        <Category></Category>
        <MobileTrustPilot />
        <Renew></Renew>
        <RaybanSunglassesBlock />
        <BogoBlock />
        <DiyBlock />
        <FeaturedCategories />
        <Testimonials></Testimonials>
        <AutoRefillBlock />
        <OnlineEyeExam />
        <VirtualTryOnBlock />
        <AboutUsBlock />
        <FaqBlock />
    </div>
</template>

<script>
import MobileProductTypeBubbles from '@/components/landing-page/home/MobileProductTypeBubbles';
import Hero from '@/components/landing-page/home/Hero.vue';
import HeroSlider from '@/components/landing-page/home/HeroSlider.vue';
import Trustpilot from '@/components/landing-page/home/Trustpilot.vue';
import Brand from '@/components/landing-page/home/Brand.vue';
import Category from '@/components/landing-page/home/Category.vue';
import Renew from '@/components/landing-page/home/Renew.vue';
import Testimonials from '@/components/landing-page/home/Testimonials.vue';
import BlueLight from  '@/components/landing-page/home/BlueLight.vue';
import Meet from  '@/components/landing-page/home/Meet.vue';
import MobileTrustPilot from '@/components/landing-page/home/MobileTrustPilot';
import RaybanSunglassesBlock from '@/components/landing-page/home/RaybanSunglassesBlock';
import DiyBlock from '@/components/landing-page/home/DiyBlock';
import OnlineEyeExam from '@/components/landing-page/home/OnlineEyeExam';
import AutoRefillBlock from '@/components/landing-page/home/AutoRefillBlock';
import VirtualTryOnBlock from '@/components/landing-page/home/VirtualTryOnBlock';
import FeaturedCategories from '@/components/landing-page/home/FeaturedCategories';
import AboutUsBlock from '@/components/landing-page/home/AboutUsBlock';
import FaqBlock from '@/components/landing-page/home/FaqBlock';
import BogoBlock from '@/components/landing-page/home/BogoBlock';

export default {
    components: {
        Hero,
        HeroSlider,
        Trustpilot,
        Brand,
        Category,
        Renew,
        Testimonials,
        BlueLight,
        Meet,
        MobileTrustPilot,
        RaybanSunglassesBlock,
        DiyBlock,
        OnlineEyeExam,
        AutoRefillBlock,
        VirtualTryOnBlock,
        FeaturedCategories,
        AboutUsBlock,
        FaqBlock,
        MobileProductTypeBubbles,
        BogoBlock
    }
}
</script>
