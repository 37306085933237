<template>
    <div class="container delancey-homepage-block">
        <img :src="formatAssetUrl('images/homepage/rayban-sunglasses-mobile-hp-tile-compressed.png')" alt="ray ban promo" class="mobile-image" />
        <div class="block-text-content">
            <h6>
                Ray-Ban
            </h6>
            <p>
                Step into style with our exclusive Ray-Ban collection.
            </p>
            <div class="button-wrapper">
                <nuxt-link to="/ray-ban" class="btn btn-black shop-now">
                    Glasses
                </nuxt-link>
                <nuxt-link to="/ray-ban-sunglasses" class="btn btn-black shop-now">
                    Sunglasses
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin]
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/font.scss';
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/components/landing-page/home/product-blocks.scss';
@import '~/assets/scss/variables/base-url.scss';

.container {
    background-color: #dedede;
    background-image: url($baseAssetUrl+'images/homepage/rayban-sunglasses-hp-tile-compressed.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    color: #ffffff;
    .block-text-content {
        .button-wrapper {
            .btn-black {
                border: 0px;
            }
        }
    }
}
.link {
    padding: 16px 0;
}
@media (max-width: $breakpoint4) {
    .container {
        color: #000000;
        .block-text-content {
            .button-wrapper {
                display: flex;
                flex-direction: column-reverse;
                gap: 10px;
                .shop-now {
                    display: block;
                    font-weight: bold;
                }
                .btn-outline {
                    display: none;
                }
            }
        }
    }
}
</style>
